export const projects = [
  {
    id: 1,
    title: 'Everything But The House',
    to: '/projects/ebth',
  },
  {
    id: 2,
    title: 'Twilight Imperium score tracker',
    to: '/projects/twilight-imperium-score-tracker',
  },
  {
    id: 3,
    title: 'Pathfinder initiative tracker',
    to: '/projects/pathfinder-initiative-tracker',
  },
  {
    id: 4,
    title: 'Mindfully website redesign',
    to: '/projects/mindfully-website',
  },
  {
    id: 5,
    title: 'Mindfully app redesign',
    to: '/projects/mindfully-app',
  },
  {
    id: 6,
    title: 'WinHalf',
    to: '/projects/winhalf',
  },
];

export const designs = [
  {
    id: 1,
    title: 'Camp Trucco t-shirts',
    to: '/designs/camp-trucco',
  },
  {
    id: 2,
    title: 'Graphics and tees',
    to: '/designs/presidents',
  },
  {
    id: 3,
    title: 'Painted miniatures',
    to: '/designs/miniatures',
  },
  {
    id: 4,
    title: 'Snowpals poster',
    to: '/designs/snowpals',
  },
  {
    id: 5,
    title: 'Home Space',
    to: '/designs/homespace',
  },
];
